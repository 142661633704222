<template>
  <div style="width: 100%">
    <v-col cols="12">
      <template v-for="(option, i) in optionAnswerMultipleChoice">
        <v-row align="center" :key="i" class="mb-1">
          <div :style="[hasConfigPhonetic ? {'width': '60%'} : {'width': '100%'}]" class="d-flex">
            <p class="text-h5 mr-2 mt-1" style="color: #6495ed">
              {{ i | getAlphabetCharacterFromIndex }}.
            </p>
            <v-checkbox
              class="mt-1 pt-0"
              v-model="option.checked"
              hide-details
            ></v-checkbox>
            <v-text-field
              :ref="'multiple-choice-'+i"
              outlined dense
              class="mr-2"
              :label="'Option' + (i + 1)"
              v-model="option.value"
            ></v-text-field>

            <v-btn
                v-if="hasConfigPhonetic"
                outlined color="#039BE5"
                class=""
                @click="replacePinyinByTextSelectedViaInputOptionRef('multiple-choice-'+i, i)"
            >
              Thêm phiên âm
            </v-btn>

            <v-btn
                outlined color="grey"
                class="mb-2" @click="deleteOptionMultipleChoice(i)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <div style="width: 40%; border: 1px solid grey" v-if="hasConfigPhonetic" class="rounded">
            <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview</p>
            <div
                class="rounded px-1 py-2 container-pinyin-preview"
                v-html="option.value">
            </div>
          </div>
        </v-row>
      </template>
    </v-col>
    <v-col cols="3">
      <v-btn large @click="addOptionMultipleChoice">
        <v-icon>mdi-plus</v-icon> Add Option
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import phoneticSupport from "../Support/Question/phoneticSupport";

export default {
  name: "MultipleChoice",
  mixins: [
      phoneticSupport
  ],
  props: {
    option: {
      type: Array,
      default: () => [],
    },
    correct: {
      type: String,
      default: "",
    },
    hasConfigPhonetic: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    optionAnswerMultipleChoice: {
      get() {
        return this.option;
      },
      set(value) {
        this.$emit("setOptionAnswer", value);
      },
    },
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
  },
  methods: {
    messError(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    deleteOptionMultipleChoice(i) {
      this.optionAnswerMultipleChoice.splice(i, 1);
    },
    addOptionMultipleChoice() {
      let data = {
        value: "",
        checked: false,
      };
      this.optionAnswerMultipleChoice.push(data);
    },
    async replacePinyinByTextSelectedViaInputOptionRef(inputRef = '', indexOption) {
      if (!inputRef) {
        this.messError('Có lỗi ! Hãy báo bên kỹ thuật');
        return;
      }
      let refOption = this.$refs[inputRef][0].$refs;
      let input = refOption.input;

      const textSelected = input.value.substring(
          input.selectionStart,
          input.selectionEnd,
      );

      if (!textSelected) {
        this.messError('Chưa chọn vùng text để lấy pinyin');
        return;
      }

      const starSto = input.value.substring(0, input.selectionStart);
      const endSto = input.value.substring(input.selectionEnd, input.value.length);

      const dataPinyin = await this.getDataPinyinByTextSelected(textSelected);
      if (!dataPinyin) return;

      this.optionAnswerMultipleChoice[indexOption].value =
          starSto +
          " " + dataPinyin + " " +
          endSto
      ;

      setTimeout(() => {
        input.focus();
        input.selectionStart = starSto.length + dataPinyin.length +2;
        input.selectionEnd = starSto.length + dataPinyin.length +2;
      }, 10);

    },
  },
};
</script>

<style scoped>
.container-pinyin-preview {
  height: auto;
  min-height: 40px;
  font-size: 28px;
  line-height: normal;
}
</style>
